import React, { useEffect, useState } from 'react'
// import Bg from '../../assets/Images/BannerImg.webp';
import { motion } from 'framer-motion';
import { IconMail, IconMapPin, IconPhone,IconLoader, IconCircleCheck, IconExclamationCircle } from '@tabler/icons-react';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    companyName: "",
  });

  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState("idle");

  useEffect(() => {
    if (status !== "idle") {
      const timer = setTimeout(() => setStatus("idle"), 5000);
      return () => clearTimeout(timer);
    }
  }, [status]);

  const validate = () => {
    const newErrors = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Name is required.";
    } else if (/\d/.test(formData.fullName)) {
      newErrors.name = "Name cannot contain numbers.";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      newErrors.email = "Invalid email address.";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }

    if (!formData.companyName.trim()) {
      newErrors.companyName = "Company name is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        setStatus("loading");
        const response = await fetch("https://offisbay.com/contactus.php", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          setStatus("success");
          setErrors({});
          setFormData({
            fullName: "",
            email: "",
            phone: "",
            companyName: "",
          });
        } else {
          setStatus("error");
        }
      } catch (error) {
        setStatus("error");
      }
    }
  };
  return (
<section 
  className="h-full py-20 flex flex-col items-center justify-center font-RedHat border-t-2" 
  // style={{
  //   background: `linear-gradient(
  //       rgba(150, 99, 118, 0.5), /* Semi-transparent gradient color */
  //       rgba(150, 99, 118, 0.5) /* Semi-transparent gradient color */
  //     ), url(${Bg})`, /* Background image URL */
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  //   backgroundRepeat: 'no-repeat'
  // }}
>
  <div className='w-[90%] h-full flex flex-row  flex-wrap gap-5 lg:gap-0'>
    <div className='text-black flex flex-col gap-5 lg:w-1/2 w-full'>
    <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            className="text-4xl font-RedHat font-bold text-primary "
          >
            Get in touch
          </motion.h2>
    <ul className='flex flex-col gap-5'>
        <li className='flex flex-col gap-3'>
            
                <h4 className='font-bold text-[1rem]'>Our Location</h4>
                <p className='flex flex-row gap-2 items-center font-semibold'>
                <IconMapPin size={18}/>
                #191, 3rd Floor, Hamid Building, Whites Road, Anna Salai, Chennai – 600 006
                </p>
            
        </li>
        <li className='flex flex-col gap-3'>
            
                <h4 className='font-bold text-[1rem]'>Phone</h4>
                <a href='tel:+919600466139' className='flex flex-row gap-2 items-center font-semibold'>
                <IconPhone size={18}/>
                +91 9600466139
                </a>
            
        </li>
        <li className='flex flex-col gap-3'>
            
                <h4 className='font-bold text-[1rem]'>Email</h4>
                <a href='mailto:reachus@offisbay.com' className='flex flex-row gap-2 items-center font-semibold'>
                <IconMail size={18}/>
                reachus@offisbay.com
                </a>
            
        </li>
    </ul>
    </div>
    <div className='text-white flex flex-col gap-5 lg:w-1/2 w-full relative overflow-hidden'>
    <form className='bg-primary/60 py-10 px-5 rounded-lg w-full flex flex-col gap-5' onSubmit={handleSubmit}>
      <div className='flex flex-col gap-2 w-full'>
      <label htmlFor="fullName" className='text-xs'>Name</label>
          <input 
            type="text" 
            name='fullName' 
            id='fullName'  
            className={`outline-none border-2 indent-4 text-black focus:border-primary rounded-lg py-1 ${errors.fullName ? 'border-red-500' : 'border-transparent'}`}
            value={formData.fullName}
            onChange={handleChange}
          />
          {errors.fullName && <p className="text-red-500 text-xs">{errors.fullName}</p>}
      </div>
      <div className='flex flex-col gap-2 w-full'>
        <label htmlFor="email" className='text-xs'>Email</label>
        <input 
          type="email" 
          name='email' 
          id='email' 
          className={`outline-none border-2 indent-4 text-black focus:border-primary rounded-lg py-1 ${errors.email ? 'border-red-500' : 'border-transparent'}`}
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
      </div>

      <div className='flex flex-col gap-2 w-full'>
        <label htmlFor="phone" className='text-xs'>Phone</label>
        <input 
          type="tel" 
          name='phone' 
          id='phone' 
          minLength={10}
          maxLength={10}
          className={`outline-none border-2 indent-4 text-black focus:border-primary rounded-lg py-1 ${errors.phone ? 'border-red-500' : 'border-transparent'}`}
          value={formData.phone}
          onChange={handleChange}
        />
        {errors.phone && <p className="text-red-500 text-xs">{errors.phone}</p>}
      </div>

      <div className='flex flex-col gap-2 w-full'>
        <label htmlFor="companyName" className='text-xs'>Company Name</label>
        <input 
          type="text" 
          name='companyName' 
          id='companyName' 
          className={`outline-none border-2 indent-4 text-black focus:border-primary rounded-lg py-1 ${errors.companyName ? 'border-red-500' : 'border-transparent'}`}
          value={formData.companyName}
          onChange={handleChange}
        />
        {errors.companyName && <p className="text-red-500 text-xs">{errors.companyName}</p>}
      </div>

      <motion.button
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: false }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
        className="bg-primary py-2 px-8 text-[0.8rem] rounded-md font-Montserrat relative group"
        type="submit"
      >
        <div className="absolute inset-0 bg-white/10 w-0 group-hover:w-full transition-all ease-in-out duration-300 rounded-md"></div>
      Submit
      </motion.button>

      {/* {successMessage && <p className="text-green-500 text-sm mt-2">{successMessage}</p>} */}
    </form>
    {status === "loading" && (
              <div className="absolute inset-0 rounded-lg bg-white/50 flex flex-col items-center justify-center gap-5">
                <motion.div
                  initial={{ opacity: 0.5, rotate: 0 }}
                  animate={{ opacity: 1,rotate: 360 }}
                  transition={{
                    duration: 3,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: "mirror",
                  }}
                >
                  <IconLoader size={54} className='stroke-green-600' />
                </motion.div>
                <h3 className="text-base font-semibold text-green-600">
                  Sending message . . .
                </h3>
              </div>
            )}
            {status === "success" && (
              <div className="absolute inset-0 bg-white/50 rounded-lg flex flex-col items-center justify-center gap-5">
                <motion.div
                  initial={{ opacity: 0.5, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 1.5,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: "mirror",
                  }}
                >
                  <IconCircleCheck size={54} className='stroke-green-600'/>
                </motion.div>
                <h3 className="text-base font-semibold text-green-600">
                  Your message has been sent successfully
                </h3>
              </div>
            )}
            {status === "error" && (
              <div className="absolute inset-0 bg-white/50 rounded-lg flex flex-col items-center justify-center gap-5">
                <motion.div
                  initial={{ opacity: 0.5, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 1.5,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: "mirror",
                  }}
                >
                  <IconExclamationCircle size={54} className='stroke-red-600' />
                </motion.div>
                <h3 className="text-base font-semibold capitalize text-red-600">
                  Something went wrong please try again later
                </h3>
              </div>
            )}
    </div>

  </div>
</section>
  )
}

export default ContactUs