import React, { useEffect, useState } from "react";
import BannerImg from "../../assets/Images/BannerImg.webp";
import { easeInOut, motion } from "framer-motion";
import "./style.css";
import { IconCircleCheck, IconDotsVertical, IconExclamationCircle, IconLoader, IconSignRight } from "@tabler/icons-react";
import { Carousel } from "@material-tailwind/react";
import f1 from "../../assets/Images/wifi.png";
import f2 from "../../assets/Images/Car.png";
import f3 from "../../assets/Images/Coffee.png";
import f4 from "../../assets/Images/Netwrok.png";
import f5 from "../../assets/Images/Priting.png";
import f6 from "../../assets/Images/Support.png";
import f7 from "../../assets/Images/Office.png";
import f8 from "../../assets/Images/Housekeeping.png";
import m1 from "../../assets/Images/m1.webp";
import m2 from "../../assets/Images/m2.webp";
import m3 from "../../assets/Images/m3.webp";
import m4 from "../../assets/Images/m4.webp";
import m5 from "../../assets/Images/m5.webp";
import m6 from "../../assets/Images/m6.webp";
import p1 from "../../assets/Images/c1.webp";
import p2 from "../../assets/Images/c2.webp";
import p3 from "../../assets/Images/c3.webp";
import p4 from "../../assets/Images/c4.webp";

import metro from "../../assets/Images/metro.png";
import movie from "../../assets/Images/movie.png";
import bus from "../../assets/Images/busstop.png";
import sports from "../../assets/Images/football.png";
import mall from "../../assets/Images/mall.png";
import ac from "../../assets/Images/ac.png";
import power from "../../assets/Images/power.png";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
const Home = () => {
  const coworkingData = [
    {
      id: 1,
      title: "Co-working Spaces",
      descriptionPoints: [
        "Trade in cubicles for conversations, and isolation for inspiration! Whether you’re a solo freelancer or part of a dynamic duo, our vibrant co-working spaces are designed to fuel creativity and boost productivity. Pick your spot, grab a coffee, and get to work alongside some seriously cool people.",
        "Jump into the Commons and create something amazing today!",
      ],
      coverImage: m1,
      sliderImages: [m1, m2],
    },
    {
      id: 2,
      title: "Meeting Rooms ",
      descriptionPoints: [
        "Why settle for boring boardrooms when you can have Brainstorm Boulevard? Whether it’s a big pitch, a team huddle, or a strategy session, our stylish and tech-savvy meeting rooms are ready to handle it all—with a side of free coffee and a whole lot of good vibes.",
        "Book your spot on the Boulevard and make things happen!",
      ],
      coverImage: m2,
      sliderImages: [m2, m3],
    },
    {
      id: 3,
      title: "Virtual Office Services",
      descriptionPoints: [
        "Make a big impression without the big expense! With our Virtual Office Services, get a prestigious address, mail handling, and all the perks of an office without actually having to be there. It’s your office in the cloud, so you can stay grounded wherever you are.",
        "Get your Cloud HQ and level up your business presence!",
      ],
      coverImage: m6,
      sliderImages: [m3, m4],
    },
    {
      id: 4,
      title: "Business Support Services",
      descriptionPoints: [
        "Leave the grunt work to us! Our Task Force is here to handle your admin, tech troubles, and all those pesky little things that steal your focus. We keep the gears turning so you can keep the ideas flowing.",
        "Deploy the Task Force and focus on what you do best!",
      ],
      coverImage: m4,
      sliderImages: [m4, m5],
    },
  ];

  const workSpaceData = [
    {
      id: 1,
      roomName: "Hot Desk",
      image: m1,
    },
    {
      id: 2,
      roomName: "Private Rooms",
      image: m2,
    },
    {
      id: 3,
      roomName: "Virtual Office",
      image: m3,
    },
    {
      id: 4,
      roomName: "Meeting Rooms",
      image: m4,
    },
  ];

  const features = [
    {
      id: 1,
      title: "Lightning-fast Internet",
      image: f1,
    },
    {
      id: 2,
      title: "Great Coffee, Tea and Munchies",
      image: f3,
    },
    {
      id: 3,
      title: "Super Housekeeping",
      image: f8,
    },
    {
      id: 4,
      title: "Professional IT Support",
      image: f6,
    },
    {
      id: 5,
      title: "Efficient Printing and Scanning Service",
      image: f5,
    },
    {
      id: 6,
      title: "Office Supplies",
      image: f7,
    },
    {
      id: 7,
      title: "Car Parking",
      image: f2,
    },
    {
      id: 8,
      title: "AC",
      image: ac,
    },
    {
      id: 9,
      title: "Power Backup",
      image: power,
    },
  
  ];
  const nearbyConvenience = [
    {
      id: 1,
      title: "Metro Rail",
      distance:'(100m)',
      image: metro,
    },
    {
      id: 2,
      title: "Bus Stop",
      distance:'(50m)',
      image: bus,
    },
    {
      id: 3,
      title: "Nearby Mall",
      distance:'(500m)',
      image: mall,
    },
    {
      id: 4,
      title: "Independent Sports Center",
      distance:'(10m)',
      image: sports,
    },
    {
      id: 5,
      title: "Movie Theater",
      distance:'(100m)',
      image: movie,
    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const [formData, setFormData] = useState({
      fullName: "",
      email: "",
      phone: "",
    });
  
    const [errors, setErrors] = useState({});
    const [status, setStatus] = useState("idle");
  
    useEffect(() => {
      if (status !== "idle") {
        const timer = setTimeout(() => setStatus("idle"), 5000);
        return () => clearTimeout(timer);
      }
    }, [status]);
  
    const validate = () => {
      const newErrors = {};
  
      if (!formData.fullName.trim()) {
        newErrors.fullName = "Name is required.";
      } else if (/\d/.test(formData.fullName)) {
        newErrors.name = "Name cannot contain numbers.";
      }
  
      if (!formData.email.trim()) {
        newErrors.email = "Email is required.";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
        newErrors.email = "Invalid email address.";
      }
  
      if (!formData.phone.trim()) {
        newErrors.phone = "Phone number is required.";
      } else if (!/^\d{10}$/.test(formData.phone)) {
        newErrors.phone = "Phone number must be 10 digits.";
      }
  
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (validate()) {
        try {
          setStatus("loading");
          const response = await fetch("https://offisbay.com/contactus.php", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
          });
  
          if (response.ok) {
            setStatus("success");
            setErrors({});
            setFormData({
              fullName: "",
              email: "",
              phone: "",
            });
          } else {
            setStatus("error");
          }
        } catch (error) {
          setStatus("error");
        }
      }
    };


  return (
    <>
      <section
        id="requestcallback"
        className="min-h-screen flex flex-col items-center justify-center font-RedHat "
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),url(${BannerImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          // backgroundAttachment: "fixed",
        }}
      >
        <div className="w-[90%] h-full text-white flex flex-col items-center gap-5 lg:mt-36 md:mt-36">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            className="text-4xl font-RedHat font-bold text-center"
          >
            Your Next Great Workspace Awaits
          </motion.h2>
          <p className="text-center font-Montserrat">
            Get Started Today! Fill in your details below and take the first
            step toward transforming your work life
          </p>
          <div className="relative h-fit  w-fit ">
          <form className="flex flex-col gap-4 items-center justify-center w-full" onSubmit={handleSubmit}>
      <div className="flex flex-row flex-wrap items-center justify-center gap-5 w-fit">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
        >
          <input
            type="text"
            id="fullName"
            name="fullName"
            placeholder="Name"
            value={formData.fullName}
            onChange={handleChange}
            className={`outline-none border-2 py-1 rounded-md indent-3 text-primary ${errors.fullName ? 'border-red-500' : 'border-transparent'} focus:border-primary`}
          />
          {errors.fullName && <p className="text-red-500 text-sm">{errors.fullName}</p>}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
        >
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className={`outline-none border-2 py-1 rounded-md indent-3 text-primary ${errors.email ? 'border-red-500' : 'border-transparent'} focus:border-primary`}
          />
          {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
        >
          <input
            type="tel"
            id="phone"
            name="phone"
            min={10}
            max={10}
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleChange}
            className={`outline-none border-2 py-1 rounded-md indent-3 text-primary ${errors.phone ? 'border-red-500' : 'border-transparent'} focus:border-primary`}
          />
          {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
        </motion.div>
      </div>

      <motion.button
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: false }}
        transition={{ ease: 'easeInOut', duration: 0.5 }}
        className="bg-primary py-2 px-8 text-[0.8rem] rounded-md font-Montserrat relative group"
        type="submit"
      >
        <div className="absolute inset-0 bg-white/10 w-0 group-hover:w-full transition-all ease-in-out duration-300 rounded-md"></div>
      Request a Call Back
      </motion.button>

    </form>
    {status === "loading" && (
              <div className="absolute inset-0 rounded-lg bg-white/80 flex flex-col items-center justify-center gap-5">
                <motion.div
                  initial={{ opacity: 0.5, rotate: 0 }}
                  animate={{ opacity: 1,rotate: 360 }}
                  transition={{
                    duration: 3,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: "mirror",
                  }}
                >
                  <IconLoader size={24} className='stroke-green-600' />
                </motion.div>
                <h3 className="text-base text-center font-semibold text-green-600">
                  Sending message . . .
                </h3>
              </div>
            )}
            {status === "success" && (
              <div className="absolute inset-0 bg-white/80 rounded-lg flex flex-col items-center justify-center gap-2">
                <motion.div
                  initial={{ opacity: 0.5, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 1.5,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: "mirror",
                  }}
                >
                  <IconCircleCheck size={24} className='stroke-green-600'/>
                </motion.div>
                <h3 className="text-base text-center font-semibold text-green-600">
                  Your message has been sent successfully
                </h3>
              </div>
            )}
            {status === "error" && (
              <div className="absolute inset-0 bg-white/80 rounded-lg flex flex-col items-center justify-center gap-5">
                <motion.div
                  initial={{ opacity: 0.5, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 1.5,
                    ease: "easeInOut",
                    repeat: Infinity,
                    repeatType: "mirror",
                  }}
                >
                  <IconExclamationCircle size={24} className='stroke-red-600' />
                </motion.div>
                <h3 className="text-base text-center font-semibold capitalize text-red-600">
                  Something went wrong please try again later
                </h3>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="xl:min-h-screen lg:min-h-screen font-RedHat py-10 flex flex-col items-center space-y-5">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ ease: "easeInOut", duration: 0.5 }}
          className="text-4xl font-RedHat font-bold text-center"
        >
         Explore Our <span className="text-primary">Services</span>
        </motion.h2>
        <div className="w-[90%]  flex xl:flex-row lg:flex-row flex-col items-center justify-center cursor-pointer ">
          {coworkingData.map((obj) => (
            <>
              <motion.div
                key={obj.id}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: false }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
                className="min-h-[80vh] w-[20%] lg:block xl:block hidden hover:w-[85%] transition-all duration-700 ease-in-out bg-white m-[0.1rem] relative group"
                onMouseEnter={() => setHoveredIndex(obj.id)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {hoveredIndex === obj.id ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: false }}
                      transition={{ ease: "easeInOut", duration: 0.8 }}
                      className={`absolute inset-0  w-full flex flex-row border-b-4 ${
                        obj.id % 2 === 0
                          ? "border-b-primary"
                          : "border-b-gray-400"
                      }`}
                    >
                      <div className="w-[50%] p-7 flex flex-col gap-5">
                        <h2 className="font-bold text-[1.2rem]">{obj.title}</h2>
                        <ul className="flex flex-col gap-5">
                          {obj.descriptionPoints.map((items, index) => (
                            <li className="flex flex-row gap-2" key={index}>
                              <div className="w-[5%]">
                                <IconSignRight size={25} />
                              </div>
                              {items}
                            </li>
                          ))}
                        </ul>
                        <div className="flex flex-row gap-5">
                          <motion.a href="#requestcallback"
                            initial={{ opacity: 0, y: 10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: false }}
                            transition={{ ease: "easeInOut", duration: 0.5 }}
                            className="border-2 border-primary py-2 px-8 text-[0.8rem] rounded-md font-Montserrat relative group"
                          >
                            <div className="absolute inset-0 bg-white/10 w-0 group-hover:w-full transition-all ease-in-out duration-300 rounded-md"></div>
                            Call Now
                          </motion.a>
                          <motion.a href="/offisspaces"
                            initial={{ opacity: 0, y: 10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: false }}
                            transition={{ ease: "easeInOut", duration: 0.5 }}
                            className="bg-primary border-2 text-white border-transparent py-2 px-8 text-[0.8rem] rounded-md font-Montserrat relative group"
                          >
                            <div className="absolute inset-0 bg-white/10 w-0 group-hover:w-full transition-all ease-in-out duration-300 rounded-md"></div>
                            Explore Now
                          </motion.a>
                        </div>
                      </div>

                      <div className="w-[50%] relative h-full flex">
                      <LazyLoad throttle={200}>
                        <Carousel autoplay loop>
                          {obj.sliderImages.map((items, index) => (
                           
                            <img
                              key={index}
                              src={items}
                              alt={`slide ${items}`}
                              className="h-full w-full object-cover"
                            />
                           
                          ))}
                        </Carousel>
                        </LazyLoad>
                      </div>
                    </motion.div>
                  </>
                ) : (
                  <>
              
                    <div
                      className={`absolute inset-0 flex flex-col items-center justify-end py-5 border-b-4 ${
                        obj.id % 2 === 0
                          ? "border-b-primary"
                          : "border-b-gray-400"
                      }`}
                      style={{
                        backgroundImage: `url(${obj.coverImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <h2
                        className={`transition-all duration-300 ease-in-out font-bold text-white text-center  ${
                          hoveredIndex === null || hoveredIndex === obj.id
                            ? "rotate-0"
                            : "-rotate-90 transform -translate-y-20 w-[12rem]"
                        }`}
                      >
                        {obj.title}
                      </h2>
                    </div>
                  
                  </>
                )}
              </motion.div>

              {/* Mobile Responsive Cards Dare to Touch */}
              <div
                className="min-h-[10rem] w-full lg:hidden xl:hidden block  md:hover:min-h-[25rem] hover:min-h-[35rem]  transition-all duration-300 ease-in-out bg-white m-[0.1rem] relative group"
                onMouseEnter={() => setHoveredIndex(obj.id)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                {hoveredIndex === obj.id ? (
                  <>
                    <div
                      className={`w-full flex flex-col border-l-4 ${
                        obj.id % 2 === 0
                          ? "border-l-primary"
                          : "border-l-gray-400"
                      }`}
                    >
                      <div className="w-full p-7 flex flex-col gap-5">
                        <h2 className="font-bold text-[1.2rem]">{obj.title}</h2>
                        <ul className="flex flex-col gap-5 justify-center">
                          {obj.descriptionPoints.map((items, index) => (
                            <li className="flex flex-row gap-2" key={index}>
                              <div className="w-[5%]">
                                <IconSignRight size={25} />
                              </div>
                              {items}
                            </li>
                          ))}
                        </ul>
                        <div className="flex flex-row gap-5">
                          <motion.button
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: false }}
                            transition={{ ease: "easeInOut", duration: 0.5 }}
                            className="border-2 border-primary py-2 px-8 text-[0.8rem] rounded-md font-Montserrat relative group"
                          >
                            <div className="absolute inset-0 bg-white/10 w-0 group-hover:w-full transition-all ease-in-out duration-300 rounded-md"></div>
                            Call Now
                          </motion.button>
                          <motion.button
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: false }}
                            transition={{ ease: "easeInOut", duration: 0.5 }}
                            className="bg-primary border-2 text-white border-transparent py-2 px-8 text-[0.8rem] rounded-md font-Montserrat relative group"
                          >
                            <div className="absolute inset-0 bg-white/10 w-0 group-hover:w-full transition-all ease-in-out duration-300 rounded-md"></div>
                            Explore Now
                          </motion.button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`absolute inset-0 flex flex-col items-center justify-center py-5 border-l-4 ${
                        obj.id % 2 === 0
                          ? "border-l-primary"
                          : "border-l-gray-400"
                      }`}
                      style={{
                        backgroundImage: `url(${obj.coverImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <h2
                        className={`transition-all duration-300 ease-in-out font-bold text-white text-center rotate-0
                          
                      }`}
                      >
                        {obj.title}
                      </h2>
                    </div>
                  </>
                )}
              </div>
            </>
          ))}
        </div>
      </section>
      <section className="min-h-screen font-RedHat py-10 flex flex-col items-center space-y-5">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ ease: "easeInOut", duration: 0.5 }}
          className="text-4xl font-RedHat font-bold text-center capitalize"
        >
          What We <span className="text-primary">offer</span>
        </motion.h2>
        <div className="w-[90%] flex flex-row flex-wrap  rounded-lg overflow-hidden">
          <div className="bg-primary lg:w-1/2 w-full  relative p-8 flex flex-col gap-10">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false }}
              transition={{ ease: "easeInOut", duration: 0.5 }}
              className="text-2xl font-Poppins text-white font-semibold text-center"
            >
              Perks aplenty,
              <br />
              when you are at Offisbay
            </motion.h2>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-10">
              {features.map((item, index) => (
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: false }}
                  transition={{ duration: 1, ease: "easeInOut" }}
                  key={index}
                  className="flex flex-col items-center space-y-2 text-white"
                >
                  <LazyLoad throttle={200}>
                  <div className="h-[50px]">
                    <img
                      src={item.image}
                      alt={item.title}
                      className="h-full w-full object-contain"
                    />
                    
                  </div>
                  </LazyLoad>
                  <h4 className="text-center font-medium">{item.title}</h4>
                </motion.div>
              ))}
            </div>
          </div>
          <div className="flex lg:w-1/2 w-full">
          <LazyLoad throttle={200}>
            <Carousel autoplay={true} loop={true}>
              <div className="h-full">
                <img
                  src={p2}
                  alt="Slider Image 2"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="h-full">
                <img
                  src={p3}
                  alt="Slider Image 2"
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="h-full">
                <img
                  src={p4}
                  alt="Slider Image 2"
                  className="h-full w-full object-cover"
                />
              </div>
            </Carousel>
            </LazyLoad>
          </div>
        </div>
      </section>
      <section className="font-RedHat py-10 flex flex-col items-center gap-16">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ ease: "easeInOut", duration: 0.5 }}
          className="text-4xl font-RedHat font-bold text-center"
        >
          Accessibility and Nearby <span className="text-primary">Conveniences</span>
        </motion.h2>
        <div className="w-[90%] grid grid-cols-1 lg:grid-cols-5 items-center justify-center gap-32">
          {nearbyConvenience.map((item, index) => (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: false }}
              transition={{ duration: 1, ease: "easeInOut" }}
              key={index}
              className="flex flex-col items-center space-y-2 text-black"
            >
              <div className="h-[70px]">
                <img
                  src={item.image}
                  alt={item.title}
                  className="h-full w-full object-contain"
                />
              </div>
              <h4 className="text-center font-medium text-[0.8rem] text-primary">
                {item.title}<br/>{item.distance}
              </h4>
            </motion.div>
          ))}
        </div>
      </section>

      <section className="font-RedHat py-10 flex flex-col items-center space-y-5 gap-10">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ ease: "easeInOut", duration: 0.5 }}
          className="text-4xl font-RedHat font-bold text-center"
        >
          Find Your Perfect Spot at <span className="text-primary">Offis Bay</span>
        </motion.h2>
        <div className=" w-[90%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 lg:gap-30 gap-5  place-items-center">
          {workSpaceData.map((obj, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false }}
              transition={{ ease: "easeInOut", duration: 0.9 }}
              className="h-[250px] lg:w-[285px]  md:w-[280px] w-full group  relative flex items-end justify-center rounded-xl overflow-hidden"
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${obj.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="absolute bg-primary/50  inset-x-0 h-0  group-hover:h-full transition-all ease-in-out duration-300 rounded-tl-xl rounded-br-xl overflow-hidden flex flex-col gap-5 items-center justify-center">
                <motion.button
                  onClick={() => {
                    document
                      .querySelector("#requestcallback")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: false }}
                  transition={{ ease: "easeInOut", duration: 0.5 }}
                  className="bg-primary border-2 text-white border-transparent w-[240px] py-2 px-8 text-[0.8rem] rounded-md overflow-hidden font-Montserrat relative group"
                >
                  <div className="absolute inset-0 border-2 border-transparent bg-white/10 w-0 group-hover:w-full transition-all ease-in-out duration-300 rounded-md"></div>
                  Request A Visit
                </motion.button>
                <Link to={"/offisspaces"} state={{spaceId:`${obj.id}`}}>
                  <motion.button
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: false }}
                    transition={{ ease: "easeInOut", duration: 0.5 }}
                    className="bg-primary border-2 text-white border-transparent w-[240px] py-2 px-8 text-[0.8rem] rounded-md overflow-hidden font-Montserrat relative group"
                  >
                    <div className="absolute inset-0 border-2 border-transparent bg-white/10 w-0 group-hover:w-full transition-all ease-in-out duration-300 rounded-md"></div>
                    Book Your Space
                  </motion.button>
                </Link>
              </div>
              <motion.h2 className="text-[1.3rem] w-full group-hover:hidden text-white font-bold text-center transform -translate-x-1/2 -translate-y-1/2 absolute left-1/2 top-1/2">
                {obj.roomName}
              </motion.h2>
            </motion.div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Home;
